import React, { useState } from "react";
import { Divider, Stack, styled } from "@mui/material";
import { SubMenuContent } from "components/SubMenuContent";
import { AddKericoUserDialog } from "components/AddKericoUserDialog";
import { useGeofenceOptions } from "hooks/useGeofenceOptions";
import { useAddKericoUser } from "hooks/useAddKericoUser";
import { useAddClient } from "hooks/useAddClient";
import { useSpecialityOptions } from "hooks/useSpecialityOptions";
import { ProfileSettings } from "components/ProfileSettings";
import { AddClientDialog } from "components/AddClientDialog";
import { ChatManagement } from "components/ChatManagement";
import { DefaultContractData } from "components/DefaultContractData";
import { useProfileTabOpener } from "hooks/useProfileTabOpener";
import * as SC from "SC";

const Layout = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  height: 100%;

  gap: 28px;
`;

const Menu = styled(Stack)`
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

const tabsLabels = [
  "Kerico User Management",
  "Client Management",
  "Personal Settings",
  "Chat Management",
  "Default Contract Data",
];

export const AdminSettings = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const {
    isAddUserVisible,
    setIsAddUserVisible,
    selectedCounties,
    setSelectedCounties,
  } = useAddKericoUser();
  const { countiesOptions } = useGeofenceOptions();

  const { isAddClientVisible, setIsAddClientVisible } = useAddClient();
  const { specialityOptions, loadSpecialities } = useSpecialityOptions();

  const kericoUserManagementContent = [
    {
      title: "Manage Kerico Users",
      info: "You can view and edit Kerico Manager and Dispatcher data",
      buttonLabel: "Manage Kerico Users",
      path: "/settings/manage_kerico_users",
    },
    {
      title: "Add Kerico User",
      info: "Add a Manager or Dispatcher to the Kerico Space",
      buttonLabel: "Add Kerico User",
      onClick: () => setIsAddUserVisible(true),
    },
  ];

  const clientManagementContent = [
    {
      title: "Manage Clients",
      info: "You can view and edit Client Users data",
      buttonLabel: "Manage Clients",
      path: "/settings/manage_clients",
    },
    {
      title: "Add Client",
      info: "Add a New User to the Clients Space",
      buttonLabel: "Add Client",
      onClick: () => setIsAddClientVisible(true),
    },
  ];

  useProfileTabOpener({ handleTabOpen: () => setCurrentTab(2) });

  return (
    <SC.OverflowedContainer>
      <Layout>
        <Menu>
          {tabsLabels.map((label, index) =>
            index === currentTab ? (
              <SC.ActiveSubmenu
                key={label}
                onClick={() => setCurrentTab(index)}
              >
                {label}
              </SC.ActiveSubmenu>
            ) : (
              <SC.Submenu key={label} onClick={() => setCurrentTab(index)}>
                {label}
              </SC.Submenu>
            )
          )}
        </Menu>
        <Divider orientation="vertical" />
        <Content>
          {currentTab === 0 && (
            <Stack gap={3}>
              {kericoUserManagementContent.map(
                ({ title, info, buttonLabel, path, onClick }, index) => (
                  <React.Fragment key={`settings-${title}`}>
                    {index > 0 && <Divider />}
                    <SubMenuContent
                      title={title}
                      info={info}
                      buttonLabel={buttonLabel}
                      path={path}
                      onClick={onClick}
                    />
                  </React.Fragment>
                )
              )}
              <AddKericoUserDialog
                open={isAddUserVisible}
                handleClose={() => setIsAddUserVisible(false)}
                refreshList={() => null}
                countiesOptions={countiesOptions}
                selectedCounties={selectedCounties}
                setSelectedCounties={setSelectedCounties}
              />
            </Stack>
          )}
          {currentTab === 1 && (
            <Stack gap={3}>
              <Stack gap={3}>
                {clientManagementContent.map(
                  ({ title, info, buttonLabel, path, onClick }, index) => (
                    <React.Fragment key={`clients-${title}`}>
                      {index > 0 && <Divider />}
                      <SubMenuContent
                        title={title}
                        info={info}
                        buttonLabel={buttonLabel}
                        path={path}
                        onClick={onClick}
                      />
                    </React.Fragment>
                  )
                )}
                <AddClientDialog
                  open={isAddClientVisible}
                  handleClose={() => setIsAddClientVisible(false)}
                  refreshList={() => null}
                  specialityOptions={specialityOptions}
                  loadSpecialities={loadSpecialities}
                />
              </Stack>
            </Stack>
          )}
          {currentTab === 2 && <ProfileSettings />}
          {currentTab === 3 && <ChatManagement />}
          {currentTab === 4 && <DefaultContractData />}
        </Content>
      </Layout>
    </SC.OverflowedContainer>
  );
};
