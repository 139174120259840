import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { UnderConstruction } from "pages/UnderConstruction";
import { CommonLayout } from "components/CommonLayout";
import { ReactComponent as DashboardIcon } from "assets/img/dashboard-icon.svg";
import { ReactComponent as BookingIcon } from "assets/img/bookings-icon.svg";
import { ReactComponent as BookingCsvIcon } from "assets/img/bookings-csv-icon.svg";
import { ReactComponent as DriversIcon } from "assets/img/drivers-icon.svg";
import { ReactComponent as FleetManagerIcon } from "assets/img/fleet-management-icon.svg";
import { ReactComponent as AnalitycsIcon } from "assets/img/analitycs-icon.svg";
import { ReactComponent as PaymentsIcon } from "assets/img/payments-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/img/settings-icon.svg";
import { AdminSettings } from "pages/AdminSettings";
import { ManageKericoUsers } from "pages/ManageKericoUsers";
import { ManageClients } from "pages/ManageClients";
import { ClientDetails } from "pages/ClientDetails";
import { DriversPage } from "pages/DriversPage";
import { FleetPage } from "pages/FleetPage";
import { BookingsPage } from "pages/BookingsPage";
import { CreateBookingPage } from "pages/CreateBookingPage";
import { DashboardPage } from "pages/DashboardPage";
import { PaymentsPage } from "pages/PaymentsPage";
import { CompanyInvoicesPage } from "pages/CompanyInvoicesPage";
import { Invoice } from "pages/Invoice";
import { RideChatPage } from "pages/RideChatPage";
import { DriverChatPage } from "pages/DriverChatPage";
import { ClientUserChatPage } from "pages/ClientUserChatPage";
import { RequestFilesPage } from "pages/RequestFilesPage";
import { ImportCsvFilePage } from "pages/ImportCsvFilePage";
import { RequestItemsPage } from "pages/RequestItemsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <CommonLayout
        menu={[
          {
            icon: <DashboardIcon />,
            label: "Dashboard",
            path: "/",
          },
          {
            icon: <BookingIcon />,
            label: "Booking",
            path: "/booking",
          },
          {
            icon: <BookingCsvIcon />,
            label: "Request-Files",
            path: "/request_files",
          },
          {
            icon: <DriversIcon />,
            label: "Drivers",
            path: "/drivers",
          },
          {
            icon: <FleetManagerIcon />,
            label: "Fleet Management",
            path: "/fleet_management",
          },
          {
            icon: <AnalitycsIcon />,
            label: "Analytics",
            path: "/analytics",
          },
          {
            icon: <PaymentsIcon />,
            label: "Payments",
            path: "/payments",
          },
          {
            icon: <SettingsIcon />,
            label: "Settings",
            path: "/settings",
          },
        ]}
      />
    ),
    children: [
      {
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: "/booking",
        element: <BookingsPage />,
      },
      {
        path: "/request_files",
        element: <RequestFilesPage />,
      },
      {
        path: "/request_files/:id",
        element: <RequestItemsPage />,
      },
      {
        path: "/import_csv_file",
        element: <ImportCsvFilePage />,
      },
      {
        path: "/booking/schedule_a_ride",
        element: <CreateBookingPage />,
      },
      {
        path: "/drivers",
        element: <DriversPage />,
      },
      {
        path: "/fleet_management",
        element: <FleetPage />,
      },
      {
        path: "/analytics",
        element: <UnderConstruction componentName={"SUPER_ADMIN analytics"} />,
      },
      {
        path: "/payments",
        element: <PaymentsPage />,
      },
      {
        path: "/invoice/:id",
        element: <Invoice />,
      },
      {
        path: "/company_invoices/:id",
        element: <CompanyInvoicesPage />,
      },
      {
        path: "/ride_chat/:id",
        element: <RideChatPage />,
      },
      {
        path: "/driver_chat/:id/:chatId",
        element: <DriverChatPage />,
      },
      {
        path: "/client_chat/:id/:chatId",
        element: <ClientUserChatPage />,
      },
      {
        path: "/client_chat/:id",
        element: <UnderConstruction componentName={"Driver Chat"} />,
      },
      {
        path: "/settings",
        element: <AdminSettings />,
      },
      {
        path: "/settings/manage_kerico_users",
        element: <ManageKericoUsers />,
      },
      {
        path: "/settings/manage_clients/:id",
        element: <ClientDetails />,
      },
      {
        path: "/settings/manage_clients",
        element: <ManageClients />,
      },
      {
        path: "/settings/add_kerico_users",
        element: <AdminSettings />,
      },
      {
        path: "*",
        element: <Navigate to={"/"} replace={true} />,
      },
    ],
  },
]);

const SuperAdmin = () => <RouterProvider router={router} />;

export default SuperAdmin;
