import React from "react";
import { useAppSelector } from "store/hooks";
import { ErrorMessage } from "@hookform/error-message";
import { Stack, OutlinedInput, Box } from "@mui/material";
import { useLoginPage } from "./LoginPage.hook";
import * as UI from "UI";
import * as SC from "SC";
import { useIsMobilePortraitScreen } from "hooks/useIsMobilePortraitScreen";
import { ModalSpinner } from "components/ModalSpinner";

export const LoginPage = () => {
  const spinner = useAppSelector((state) => state.spinner);
  const {
    isLoading,
    errors,
    onSubmit,
    passwordRegisterProps,
    usernameRegisterProps,
  } = useLoginPage();

  const isMobilePortrait = useIsMobilePortraitScreen();

  if (isLoading) return <ModalSpinner open={true} />;

  return (
    <SC.AuthFormContainer>
      <Stack gap={2} sx={{ minWidth: isMobilePortrait ? "280px" : "340px" }}>
        <Stack mb={2}>
          <SC.AuthTitle sx={SC.AuthTitleSx}>Welcome Back!</SC.AuthTitle>
          <SC.PlainText sx={{ textAlign: "center" }}>
            Log in to your Keriсo account
          </SC.PlainText>
        </Stack>
        <form onSubmit={onSubmit} className="mui-form">
          <Stack gap={2}>
            <Stack alignItems={"flex-start"}>
              <SC.InputLabel htmlFor="email">{"E-mail"}</SC.InputLabel>
              <OutlinedInput
                id="email"
                type="text"
                autoComplete="username"
                {...usernameRegisterProps}
                sx={{ alignSelf: "stretch" }}
                error={!!errors?.username}
                size="small"
              />
              <ErrorMessage
                errors={errors}
                name="username"
                render={({ message }) => <UI.ErrorString text={message} />}
              />
            </Stack>
            <Stack alignItems={"flex-start"}>
              <SC.InputLabel htmlFor="password">{"Password"}</SC.InputLabel>
              <OutlinedInput
                id="password"
                type="password"
                autoComplete="password"
                {...passwordRegisterProps}
                sx={{ alignSelf: "stretch" }}
                size="small"
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <UI.ErrorString text={message} />}
              />
            </Stack>
            <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
              <SC.AuthLink to="/forgot">{"Forgot password?"}</SC.AuthLink>
            </Stack>
            <SC.ControlButton
              type="submit"
              variant="contained"
              disabled={!!spinner}
            >
              Log in
            </SC.ControlButton>
          </Stack>
        </form>
        <SC.InactiveText textAlign={"center"}>
          By using the app, you agree to our
          <br />
          <SC.Terms to="/terms">
            {"Terms of Service and Privacy Policy"}
          </SC.Terms>
        </SC.InactiveText>
        {/* <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          {"Don't have an account?"}
          <SC.AuthLink to="/register">{"Create"}</SC.AuthLink>
        </Stack> */}
      </Stack>
      <Box sx={{ height: "5vh" }}></Box>
    </SC.AuthFormContainer>
  );
};
